<template>
  <div class="order-examine-popup">
    <el-dialog title="【绑定】- 车辆与驾驶员绑定" :close-on-click-modal="false" :visible.sync="visible" width="45%" center>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="车牌号" prop="vehiclePlate">
              <el-input v-model="vehiclePlate" placeholder="车牌号" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="驾驶员姓名" prop="driverId">
              <el-select class="item-choose" v-model="dataForm.driverId"
                         style="width: 100%;"
                         :disabled="isView" filterable clearable placeholder="请选择">
                <el-option label="请选择" value=""></el-option>
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in driverList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      vehiclePlate: null,
      dataForm: {
        driverId: null,
        id: null,
        motorcadeId: null
      },
      driverList: [],
      row: {},
      dataRule: {
        driverId: [
          {required: true, message: '驾驶员姓名不能为空', trigger: 'change'}
        ]
      }
    }
  },
  computed: {
    isView () {
      return false
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.dataForm.driverId = null
      this.vehiclePlate = row.vehiclePlate
      this.dataForm.motorcadeId = row.motorcadeId
      this.dataForm.id = row.id
      this.$http({
        url: this.$http.adornUrl(`/driver/findNotBindDriver`),
        method: 'GET',
        params: this.$http.adornParams({
          motorcadeId: this.dataForm.motorcadeId
        })
      }).then(({ data }) => {
        this.driverList = data
      })
    },
    confirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/vehicleInfo/bindDriver`),
            method: 'post',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败，请刷新后再试')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
